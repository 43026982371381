
export default function createBlankApp() {
    const WIDGET_URL = "https://widget.paymentcloudinc.com/";
    const MAIN_URL = "https://paymentcloudinc.com/";

    const divId = document.currentScript.getAttribute("div-id");
    const orgSlug = document.currentScript.getAttribute("org-slug");
    const agentUrl = document.currentScript.getAttribute("agent-url");
    const templateId = document.currentScript.getAttribute("template-id");
    // Check if div exist
    if(document.getElementById(divId)) {
        var data = new FormData();

        const widgetURL = WIDGET_URL + "blank-app/" +`${orgSlug}/${agentUrl}/${templateId}`;
        var oReq = new XMLHttpRequest();
        oReq.open("POST", widgetURL);

        oReq.onload = function() {
            const res = JSON.parse(this.responseText);
            if(res.status) {
                const URL = MAIN_URL + orgSlug + '/' + agentUrl + '/' + res.slug;

                var a = document.createElement('iframe');
                a.src = URL; //add your iframe path here
                a.width = "1000";
                a.height = "500";
                document.getElementById(divId).appendChild(a);
            } else {
                alert(res.message);
            }
        };
        oReq.send(data);
    } else {
        alert("Please assign ID of the div in the script, div-id (attribute)");
    }
}


createBlankApp();
